export const LOG_PREFIX = '📘'

// localStorage keys
export const LOCALSTORAGE_BOOK_ID = 'snippets.book.id'
export const LOCALSTORAGE_DOCUMENT_ID = 'snippets.document.id'

// Every book must have at least one document of this ID
export const MASTER_DOCUMENT_ID = 'index.rst'

// Job statuses
export const JOB_QUEUED = 'queued'
export const JOB_STARTED = 'started'
export const JOB_DEFERRED = 'deferred'
export const JOB_FINISHED = 'finished'
export const JOB_FAILED = 'failed'

// Editor events
export const EV_EDITOR_UNDO = 'editor.undo'
export const EV_EDITOR_INSERT = 'editor.insert'
